.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.slide-header {
  width: 66%;
  position: absolute;
  background: rgb(2,0,36);
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(34,34,91,1) 35%, rgba(40,44,52,1) 100%); 
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.App-body{
  font-size: calc(10px + 2vmin);
  min-height: 100vh;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative; /*this makes overflow hidden work for absolutely positioned child elements */
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
  display: block;
}

.padded-top {
  padding-top: 3em;
}

.image-container {
  display: inline-block;
  width: 50%;
}

.rounded-image {
  top: 0;
  left: 0;
  border-radius: 50%;
}

.shadow-img {
  -webkit-box-shadow: 35px 30px 17px -11px rgba(31,29,46,0.77);
  -moz-box-shadow: 35px 30px 17px -11px rgba(31,29,46,0.77);
  box-shadow: 35px 30px 17px -11px rgba(31,29,46,0.77);
}

.green-and-blue {
  background: #c2e59c;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #64b3f4, #c2e59c);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top right, #64b3f4, #c2e59c); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.poncho {
  background: #403A3E;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top right, #BE5869, #403A3E);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top right, #BE5869, #403A3E); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.pizelex {
  background: #114357;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #F29492, #114357);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #F29492, #114357); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.donateBtn{
  margin-left: 30%;
}