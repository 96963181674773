.icon-spin {
    -webkit-animation: icon-spin 1s infinite linear;
            animation: icon-spin 1s infinite linear;
  }
  
  @-webkit-keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }
  
  @keyframes icon-spin {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(359deg);
              transform: rotate(359deg);
    }
  }

  .clickable-hover:hover {
    cursor: pointer;
  }

  .unclickable:hover {
    cursor: default;
  }